import React from 'react';

import Layout from '../components/layouts/pl';
import HeaderSlider from '../components/sections/header-slider';
import Advantages from '../components/sections/advantages';
import LastProjects from '../components/sections/last-projects';
import Services from '../components/sections/services';
import Team from '../components/sections/team';
import OfficesFirst from '../components/sections/offices-first';
import OfficesSecond from '../components/sections/offices-second';
import OfficesThird from '../components/sections/offices-third';
import Tweets from '../components/sections/tweets';
import ClientLogos from '../components/sections/client-logos';
import Contact from '../components/sections/contact';


const Index = () => <Layout baseUrl="">
  <HeaderSlider />
  <Advantages />
  <LastProjects />
  <Services />
  <Team />
  <OfficesFirst />
  <OfficesSecond />
  <OfficesThird />
  <Tweets />
  <ClientLogos />
  <Contact />
</Layout>

export default Index